import React from 'react'
import Page from 'src/components/page'
import Container from 'src/components/container'

const NotFoundPage = () => {
  return (
    <Page className="page-404">
      <Container>
        <h1 className="centered">404 Not Found</h1>
      </Container>
    </Page>
  )
}

export default NotFoundPage
